import React, { JSX, useMemo } from 'react';
import { PaletteColor } from '@mui/material/styles/createPalette';
import { theme } from '@th-common/theme/theme';

interface IProps {
  iconName: string;
  color?: 'currentColor'
  | 'action'
  | 'disabled'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'
  | string;
  size: string;
  className?: string;
}

const defaultIconSettings: Partial<IProps> = {
  color: 'currentColor',
};

const MatIcon: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { iconName, color, ...rest } = props;
  const colorCode = useMemo(() => {
    const palette = theme.palette[color as keyof typeof theme.palette] as PaletteColor;
    return palette?.main || color;
  }, [theme.palette, color]);

  const svgIconProps = useMemo(() => ({
    fill: colorCode || defaultIconSettings.color,
    ...defaultIconSettings,
    ...rest,
  }), [colorCode, rest, defaultIconSettings]);

  return (
    <svg
      {...svgIconProps}
      style={{
        width: props.size,
        height: props.size,
      }}
    >
      <use xlinkHref={`#icon-${iconName}`} />
    </svg>
  );
};

export default MatIcon;
