'use client';

import { JSX, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MatIcon from '@th-common/components/material/MatIcon';
import { config } from '@th-common/utils/config';
import { useRouter } from 'next/navigation';

import styles from './not-found.module.scss';

function NotFoundPage(): JSX.Element {
  const router = useRouter();

  const goToDashboard = useCallback(() => {
    router.replace(config.routes.homePageUrl);
  }, [router]);

  return (
    <Box className={styles.container}>
      <Box className={styles.content}>
        <Typography
          variant='h1'
          className={styles.title}
        >
          4
          <MatIcon iconName='camera' size='12rem' />
          4
        </Typography>
        <Typography
          variant='displaySmall'
          className={styles.subtitle}
        >
          This page could not be found.
        </Typography>
        <Typography
          variant='h3'
          className={styles.description}
        >
          We apologize for any disruption to your workflow. Feel free to head back to the dashboard.
        </Typography>
        <Button
          variant='contained'
          onClick={goToDashboard}
        >
          Back to Dashboard
        </Button>
      </Box>
    </Box>
  );
}

export default NotFoundPage;

export const dynamic = 'force-static';
